import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import { useStaticQuery,graphql } from 'gatsby'

import SEO from "../components/seo";
import LayoutLp from "../components/layoutLp";
import Call from "../static/svg/icon-call.inline.svg";

export default function LpThanks( {location} ) {

  const data = useStaticQuery(graphql`
  query lpThanksQuery {
    site {
      siteMetadata {
        tel
      }
    }
  }
  
  `);

   const { pathname } = location;
   const { state = {} } = location;


  const venue = (state) ? state.venue : "";

  const inputVenue = (() => {
    switch (venue) {
      case "nishiogi": return "西荻窪";
      case "kasai": return "葛西";
      default: return "";
    }
  })();


  {/*
    console.log("log")
    if( Array.isArray(state.symptom))
    {
      console.log((state.symptom).join('、'));
    }
  */}
    


  const symptoms = (state.symptom && Array.isArray(state.symptom)) 
  ? (state.symptom).join('、 ')
  : null ;

  const pagetitle = "お問い合わせありがとうございます";
  const description = "お問い合わせありがとうございました";


   
    return (

      <div>

        <LayoutLp isThanks isSubPage >
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
          />

          <div >
            <h1 className="heading-b">お問い合わせありがとうございます</h1>

            <div>
              {state.name ?
                <p>{state.name} 様、</p>
                : null}
            
              <p>お問い合わせありがとうございます。当日中か遅くとも1～2日以内には折り返しご連絡いたしますので、今しばらくお待ちください。</p>

              {state.name ?
                <div className="mx-2">
                  <StaticImage
                    src="../images/e1.png"
                    alt="email"
                    placeholder="tracedSVG"
                    layout="constrained"
                    loading="eager"
                  />
                  <StaticImage
                    src="../images/e2.png"
                    alt="email"
                    placeholder="tracedSVG"
                    layout="constrained"
                    loading="eager"
                  />

                  よりご連絡いたします。
                </div>
                : null}

              <p>
                メールが届かない場合は、念のため迷惑メールフォルダをご確認ください。<br />
                迷惑メールフォルダにも届いていない場合は、大変お手数ですがお電話にてお問い合わせください。
              </p>


              <p>
                受付時間：平日・土曜日 10:00 ~ 18:00<br />
                <span className="text-xl">
                  <Call className="fill-current w-5 h-5 inline  mr-0.5" />
                  &nbsp;{data.site.siteMetadata.tel}
                </span> </p>
              <p>
                施術中などで、お電話に出れない場合がございます。留守番電話にメッセージをお願いいたします。折り返し、ご連絡いたします。</p>

              {state.name ?
                <>
                  <p className="mt-6">下記のお問い合わせを承りました。</p>
                  <div className="bg-gray-200 p-2">
                    <p>お名前:&nbsp;{state.name}&nbsp;様</p>
                    <p>メールアドレス:&nbsp;{state.email}</p>
                    {state.tel && <p>お電話番号:&nbsp;{state.tel}</p>}
                    {state.venue && <p>ご希望の場所:&nbsp;{inputVenue}</p>}
                    {state.datetime && <p>ご希望日時:&nbsp;{state.datetime}</p>}
                    {symptoms && <p>何にお悩みですか？:&nbsp;{symptoms }</p> }
                
                    {state.message && 
                    <>
                    <p>ご相談・お問い合わせ内容:&nbsp;<br /><span className="pl-4">{state.message}</span></p>
                    </>}
                  </div>
                </>
                : null}

            </div>

          </div>
        </LayoutLp>
      </div>

        
         
    )
}